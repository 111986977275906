import React from 'react'
import SVG from 'react-inlinesvg'
import {Box, Container, css, Flex} from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
    logo: {
        width: [200, 200],
        minWidth: [200, 200],
        height: [75, 75]
    }
}

const CompaniesBlock01 = ({content}) => {
    const {text, collection, buttons} = content

    return (
        <Container sx={{textAlign: `center`}}>
            <Box as={Reveal}>
                <ContentText content={text}/>
                {text && collection && <Divider/>}
            </Box>
            <Flex sx={{flexWrap: `wrap`, m: -3, alignItems: 'center'}}>
                {collection?.map(({text, icon}, index) => (
                    <Box key={`item-${index}`} sx={{flexGrow: 1, p: [1, 3]}}>
                        <Reveal
                            effect='fadeInGrow'
                            delay={0.2 * (index + 2)}
                            title={text?.[0]?.text}
                        >
                            {icon?.src ? (
                                <SVG src={icon.src} css={css(styles.logo)}/>
                            ) : (
                                <ContentText content={text?.[0]} variant='h3' mb='0'/>
                            )}
                        </Reveal>
                    </Box>
                ))}
            </Flex>
            {buttons && (
                <>
                    <Divider space={3}/>
                    <ContentButtons content={buttons}/>
                </>
            )}
        </Container>
    )
}
export default WithDefaultContent(CompaniesBlock01)
